













import Vue from "vue";
import Component from "vue-class-component";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

@Component({
  name: "unready",
  components: {
    LottieAnimation
  }
})
export default class UnReady extends Vue {}
